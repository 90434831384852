<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col>
          <h1>Change Password</h1>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters></v-row>
        </v-col>
      </v-row>
      <div>
        <v-alert type="info" outlined prominent>
          <strong>A strong password is required.</strong> Enter 8-100
          characters. Do not include common words or names. Combine uppercase
          letters, lowercase letters, numbers and symbols.
        </v-alert>
        <v-alert v-model="passwordForm.error" type="error" prominent>
          <strong>Error!</strong> Something went wrong when attempting to change
          your password. Please check that your current password is correct and
          try again. If this problem persists, please contact support.
        </v-alert>
        <v-card outlined>
          <v-card-text>
            <v-form
              @submit.prevent="changePassword"
              method="post"
              ref="changePasswordForm"
              id="password-form"
            >
              <v-text-field
                label="Current Password"
                v-model="passwordForm.current"
                outlined
                :type="passwordForm.show.current ? 'text' : 'password'"
                :append-icon="
                  passwordForm.show.current ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="
                  passwordForm.show.current = !passwordForm.show.current
                "
                :rules="[passwordForm.rules.required]"
              ></v-text-field>
              <v-text-field
                label="Create New Password"
                v-model="passwordForm.new"
                outlined
                counter="100"
                :type="passwordForm.show.new ? 'text' : 'password'"
                :append-icon="passwordForm.show.new ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordForm.show.new = !passwordForm.show.new"
                :rules="[
                  passwordForm.rules.required,
                  passwordForm.rules.password,
                ]"
              ></v-text-field>
              <v-text-field
                label="Confirm New Password"
                v-model="passwordForm.newConfirmed"
                outlined
                counter="100"
                :type="passwordForm.show.newConfirmed ? 'text' : 'password'"
                :append-icon="
                  passwordForm.show.newConfirmed ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="
                  passwordForm.show.newConfirmed = !passwordForm.show
                    .newConfirmed
                "
                :rules="[passwordForm.rules.required, passwordForm.rules.match]"
              ></v-text-field>
              <div>
                <v-btn
                  depressed
                  color="accent"
                  type="submit"
                  :loading="loading"
                  form="password-form"
                  class="mr-4"
                  >Save Password</v-btn
                >
                <v-btn depressed :to="{ name: 'app-selector' }">Cancel</v-btn>
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      color="success"
      >{{ snackbar.text }}</v-snackbar
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Account Settings",
          disabled: true,
        },
        {
          text: "Change Password",
          disabled: true,
        },
      ],
      passwordForm: {
        show: {
          current: false,
          new: false,
          newConfirmed: false,
        },
        current: "",
        new: "",
        newConfirmed: "",
        error: false,
        rules: {
          required: value => !!value || "Field is Required.",
          password: value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,100})/;
            return (
              pattern.test(value) ||
              "Min. 8 characters with at least one capital letter, a number and a special character."
            );
          },
          match: value =>
            value === this.passwordForm.new || "Passwords must match.",
        },
      },
      loading: false,
      snackbar: {
        open: false,
        text: "Password Changed Successfully!",
        timeout: 4000,
      },
    };
  },

  methods: {
    changePassword: function() {
      if (!this.$refs.changePasswordForm.validate()) {
        return;
      }

      const email = this.$store.getters.userEmail;
      const current_password = this.passwordForm.current;
      const password = this.passwordForm.new;
      const password_confirmation = this.passwordForm.newConfirmed;

      this.loading = true;
      this.passwordForm.error = false;

      this.$store
        .dispatch("accountSettings/changePassword", {
          email,
          current_password,
          password,
          password_confirmation,
        })
        .then(() => {
          this.passwordForm.current = "";
          this.passwordForm.new = "";
          this.passwordForm.newConfirmed = "";
          this.loading = false;
          this.snackbar.open = true;
          this.$refs.changePasswordForm.resetValidation();
        })
        .catch(() => {
          this.loading = false;
          this.passwordForm.error = true;
        });
    },
  },
};
</script>
